import * as React from "react"
import {
  Typography,
  makeStyles,
  Box,
  Container,
  Hidden,
  Button,
  Grid,
} from "@material-ui/core"
import useColor from "src/hooks/useColor"

export default function NumberedColorBlock({
  primaryBackgroundColor: pbgc,
  secondaryBackgroundColor: sbgc,
  number,
  image,
  title,
  subtitle,
  link,
  url,
}) {
  const primaryBackgroundColor = useColor(pbgc)
  const secondaryBackgroundColor = useColor(sbgc)
  const classes = useStyles({
    primaryBackgroundColor,
    secondaryBackgroundColor,
    pbgc,
  })
  return (
    <div className={classes.container}>
      <Box pt={4} pb={4}>
        <Container maxWidth="md">
          <Grid container alignItems="center" className={classes.flexContainer}>
            <Grid item sm={12} md={6}>
              <Box>
                <Typography
                  variant="h1"
                  component="h2"
                  className={classes.title}
                >
                  {title}
                </Typography>
                <Box mt={2}>
                  <Typography variant="body1">{subtitle}</Typography>
                </Box>
                {link && url && (
                  <Box mt={4}>
                    <Button
                      href={`/${url}`}
                      className={classes.button}
                      variant="contained"
                    >
                      {link}
                    </Button>
                  </Box>
                )}
              </Box>
            </Grid>
            <Grid
              item
              sm={12}
              md={6}
              container
              className={classes.imageContainer}
              alignItems="center"
            >
              <Grid item>
                <div className={classes.imgContainer}>
                  <img src={image} className={classes.img} alt="case" />
                  <div className={classes.number}>{number}</div>
                </div>
              </Grid>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </div>
  )
}

const useStyles = makeStyles(theme => ({
  container: ({ primaryBackgroundColor, secondaryBackgroundColor }) => ({
    [theme.breakpoints.up("md")]: {
      background: `linear-gradient(180deg, ${primaryBackgroundColor} 80%, ${secondaryBackgroundColor} 80%)`,
    },
    background: `linear-gradient(180deg, ${primaryBackgroundColor} 30%, ${secondaryBackgroundColor} 30%)`,
    color: theme.palette.secondary.main,
  }),
  img: {
    height: "auto",
    width: "100%",
  },
  imageContainer: {
    justifyContent: "center",
    [theme.breakpoints.up("md")]: {
      justifyContent: "flex-end",
    },
  },
  imgContainer: {
    position: "relative",
  },
  mobileImgContainer: {
    display: "flex",
    justifyContent: "center",
  },
  title: {
    marginTop: theme.spacing(12),
    [theme.breakpoints.up("md")]: {
      marginTop: 0,
    },
  },
  button: ({ pbgc }) => ({
    backgroundColor:
      pbgc === "Pink"
        ? theme.palette.primary.main
        : theme.palette.secondary.main,
    color: pbgc === "Pink" ? "#005646" : "white",
    "&:hover": {
      backgroundColor: pbgc === "Pink" ? "#F7BDBD" : "#1A8E96",
    },
    [theme.breakpoints.up("md")]: {
      backgroundColor:
        pbgc === "Pink"
          ? theme.palette.secondary.main
          : theme.palette.primary.main,
      color: pbgc === "Pink" ? "white" : "#005646",
      "&:hover": {
        backgroundColor: pbgc === "Pink" ? "#1A8E96" : "#F7BDBD",
      },
    },
    boxShadow: "none",
    fontSize: 16,
    padding: theme.spacing(1.5, 4),
    fontWeight: 500,
  }),
  flexContainer: {
    marginBottom: theme.spacing(6),
    flexDirection: "column-reverse",
    [theme.breakpoints.up("md")]: {
      flexDirection: "unset",
      marginBottom: theme.spacing(0),
    },
  },
  number: ({ primaryBackgroundColor, secondaryBackgroundColor }) => ({
    fontSize: 64,
    borderRadius: "50%",
    height: 142,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    position: "absolute",
    [theme.breakpoints.up("md")]: {
      top: 0,
      bottom: 0,
      right: "auto",
      left: -72,
      border: `solid 21px ${primaryBackgroundColor}`,
    },
    bottom: -72,
    margin: "auto",
    right: 0,
    left: 0,
    width: 142,
    color: theme.palette.common.white,
    backgroundColor: theme.palette.secondary.main,
    border: `solid 21px ${secondaryBackgroundColor}`,
  }),
}))
