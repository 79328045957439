import React from "react"
import { makeStyles, Typography } from "@material-ui/core"
import useColor from "src/hooks/useColor"

export default function Stepper({
  image,
  title,
  text,
  numberBackgroundColor,
  numberColor,
  isImageFirst,
  displayNumber,
  number,
  backgroundColor,
}) {
  const blocBackgroundColor = useColor(backgroundColor)
  const color = useColor(numberColor)
  const numberBackgroundColorComputed = useColor(numberBackgroundColor)

  const classes = useStyle({
    isImageFirst,
    image,
    displayNumber,
    color,
    blocBackgroundColor,
    numberBackgroundColorComputed,
  })
  return (
    <div className={classes.container}>
      <div className={classes.inner}>
        <div className={classes.imageContainer}>
          <div className={classes.image} />
        </div>
        <div className={classes.textBloc}>
          <div className={classes.textBlocInner}>
            {displayNumber && (
              <div className={classes.numberContainer}>
                <div className={classes.number}>{number}</div>
              </div>
            )}
            <Typography variant="h1" component="h2" color="secondary">
              {title}
            </Typography>
            <Typography color="secondary" className={classes.text}>
              {text}
            </Typography>
          </div>
        </div>
      </div>
    </div>
  )
}

const useStyle = makeStyles(theme => ({
  text: { marginTop: theme.spacing(2) },
  container: {
    backgroundColor: ({ blocBackgroundColor }) => blocBackgroundColor,
    paddingTop: 50,
    paddingBottom: 100,
  },
  inner: {
    maxWidth: 900,
    width: "100%",
    margin: "auto",
    display: "flex",
    alignItems: "center",
    justifyContent: ({ isImageFirst }) =>
      isImageFirst ? "flex-start" : "flex-end",
    position: "relative",
    ["@media (max-width:580px)"]: {
      flexDirection: "column",
      justifyContent: "center",
    },
  },
  numberContainer: {
    position: "absolute",
    top: -80,
    left: "10%",
    right: "10%",
    display: "flex",
    justifyContent: "center",
  },
  number: {
    width: 60,
    height: 60,
    color: ({ color }) => color,
    borderRadius: 50,
    border: `1px solid ${theme.palette.secondary.main}`,
    backgroundColor: ({ numberBackgroundColorComputed }) =>
      numberBackgroundColorComputed,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontWeight: 700,
    fontSize: 30,
  },
  textBloc: {
    maxWidth: 430,
    backgroundColor: "white",
    zIndex: 2,
    padding: 50,
    position: "absolute",
    right: ({ isImageFirst }) => (isImageFirst ? 0 : "unset"),
    left: ({ isImageFirst }) => (!isImageFirst ? 0 : "unset"),
    ["@media (max-width:580px)"]: {
      marginRight: theme.spacing(2),
      marginLeft: theme.spacing(2),
      position: "unset",
      padding: ({ displayNumber }) => (displayNumber ? 50 : 20),
      paddingTop: 20,
      width: `calc(100% - ${theme.spacing(4)}px)`,
      maxWidth: "unset",
      marginBottom: -60,
      order: 0,
    },
  },
  textBlocInner: {
    position: "relative",
  },
  imageContainer: {
    width: "60%",
    paddingBottom: "74%",
    position: "relative",
    ["@media (max-width:580px)"]: {
      width: "100%",
      paddingBottom: "150%",
      order: 1,
    },
  },
  image: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    objectFit: "cover",
    background: ({ image }) => `no-repeat center url('${image}')`,
  },
}))
