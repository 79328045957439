import React from "react"
import {
  makeStyles,
  Typography,
  Grid,
  Box,
  MenuItem,
  Container,
  Button,
} from "@material-ui/core"
import useColor from "src/hooks/useColor"
import { GoogleAutoComplete } from "components/googleAutoComplete/GoogleAutoComplete"
import { RoundedSelect } from "components/roundedSelect/RoundedSelect"
import { RoundedInput } from "components/roundedInput/RoundedInput"
import PlaceIcon from "@material-ui/icons/Place"
import { repairStorage } from "../../helpers/repairStorage"
import Arrow from "./assets/arrow.svg"

export default function RepairForm({
  title,
  link,
  linkColor,
  products,
  backgroundColor,
  secondBackgroundColor,
  linkText,
  color,
  searchTitle,
}) {
  const topColor = useColor(backgroundColor)
  const bottomColor = useColor(secondBackgroundColor)
  const linkColorComputed = useColor(linkColor)
  const titleColor = useColor(color)

  const classes = useStyle({
    topColor,
    bottomColor,
    linkColorComputed,
    titleColor,
  })
  React.useEffect(() => {
    repairStorage.remove()
  }, [])
  const handleSelect = React.useCallback(
    ({ target: { value } }) => {
      const product = products.find(product => product._id === value)
      if (!product) {
        return
      }
      repairStorage.setProduct(product)
    },
    [products]
  )

  const handleChangeAddress = React.useCallback(address => {
    repairStorage.setAddress(address)
  }, [])

  return (
    <div className={classes.bloc}>
      <Container maxWidth="md" className={classes.container}>
        <Typography variant="h2" className={classes.title}>
          {title}
        </Typography>
        <Grid container className={classes.desktopContainer}>
          <Grid item className={classes.firstItem}>
            <RoundedSelect
              fullWidth
              placeholder="Appareil à réparer"
              onChange={handleSelect}
              height={47}
            >
              {products &&
                products.map(product => (
                  <MenuItem key={product._id} value={product._id}>
                    {product.name}
                  </MenuItem>
                ))}
            </RoundedSelect>
          </Grid>
          <Grid item className={classes.secondItem}>
            <GoogleAutoComplete onChange={handleChangeAddress}>
              <RoundedInput
                fullWidth
                height={47}
                placeholder="Mon adresse"
                InputProps={{
                  endAdornment: <PlaceIcon style={{ color: "#A4E7DB" }} />,
                }}
              />
            </GoogleAutoComplete>
          </Grid>
          <Grid item className={classes.thirdItem}>
            <Button
              href="/rendez-vous-reparateur/choix-de-creneau"
              color="primary"
              className={classes.button}
              variant="contained"
            >
              {searchTitle || "Je fais appel à un expert"}
            </Button>
          </Grid>
        </Grid>
        <a href={link} className={classes.link}>
          {linkText}
          <Arrow className={classes.arrow} />
        </a>
      </Container>
    </div>
  )
}

const useStyle = makeStyles(theme => ({
  button: {
    fontWeight: 700,
    paddingLeft: theme.spacing(3),
    height: 47,
    paddingRight: theme.spacing(3),
    ["@media(max-width: 1000px)"]: {
      width: "100%",
      fontWeight: 500,
      fontSize: 18,
    },
  },
  link: {
    position: "absolute",
    bottom: 90,
    color: ({ linkColorComputed }) => linkColorComputed,
    display: "flex",
    alignItems: "center",
    fontWeight: 700,
    fontSize: 18,
    "&:hover": {
      cursor: "pointer",
      textDecoration: "underline",
    },
  },
  arrow: {
    fill: ({ linkColorComputed }) => linkColorComputed,
    width: 30,
    marginLeft: theme.spacing(2),
    marginTop: 2,
  },
  container: {
    position: "relative",
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    paddingTop: 140,
    paddingBottom: 140,
  },
  desktopContainer: {
    position: "relative",
    padding: theme.spacing(2),
    borderRadius: 50,
    maxWidth: 924,
    margin: "auto",
    backgroundColor: theme.palette.common.white,
    boxShadow: "30.1073055267334px 0px 45px 0px #00000010",
    ["@media(max-width: 1000px)"]: {
      maxWidth: 600,
      flexDirection: "column",
      padding: 34,
      borderRadius: 20,
    },
  },
  title: {
    position: "absolute",
    color: ({ titleColor }) => titleColor,
    fontWeight: 700,
    top: 60,
  },
  firstItem: {
    flex: 1,
    ["@media(max-width: 1000px)"]: {
      marginBottom: theme.spacing(),
    },
  },
  secondItem: {
    flex: 1,
    marginRight: theme.spacing(),
    marginLeft: theme.spacing(),
    ["@media(max-width: 1000px)"]: {
      marginBottom: theme.spacing(),
      marginRight: 0,
      marginLeft: 0,
    },
  },
  thirdItem: {
    // flex: 0,
    ["@media(max-width: 1000px)"]: {
      flex: 1,
    },
  },
  bloc: {
    background: ({ topColor, bottomColor }) =>
      `linear-gradient(${
        topColor === "inherit" ? "transparent" : topColor
      } 50%, ${bottomColor === "inherit" ? "transparent" : bottomColor} 50%)`,
  },
}))
