import React from "react"
import { makeStyles, Typography } from "@material-ui/core"

import useColor from "src/hooks/useColor"

export default function StepperSlider({
  stepperSlides,
  title,
  text,
  backgroundColor,
  displayNumber,
  numberBackgroundColor,
  numberColor,
  number,
  isQuoteSlider,
}) {
  const blocBackgroundColor = useColor(backgroundColor)
  const color = useColor(numberColor)
  const numberBackgroundColorComputed = useColor(numberBackgroundColor)

  const classes = useStyle({
    blocBackgroundColor,
    color,
    isQuoteSlider,
    numberBackgroundColorComputed,
    displayNumber,
  })
  return (
    <div className={classes.outsideContainer}>
      <div className={classes.container}>
        <div className={classes.textBloc}>
          <div className={classes.textBlocInner}>
            {displayNumber && (
              <div className={classes.numberBlocContainer}>
                <div className={classes.numberBloc}>{number}</div>
              </div>
            )}
            <Typography variant="h1" component="h2" color="secondary">
              {title}
            </Typography>
            <Typography color="secondary" className={classes.text}>
              {text}
            </Typography>
          </div>
        </div>
        <div className={classes.slidesContainer}>
          {stepperSlides.map(slide => (
            <div key={slide.number} className={classes.slide}>
              {isQuoteSlider && (
                <Typography variant="h1" className={classes.quote}>
                  “
                </Typography>
              )}
              {!isQuoteSlider && (
                <div
                  style={{
                    background: `no-repeat center url('${slide.image}')`,
                    backgroundSize: "cover",
                  }}
                  className={classes.slideImage}
                />
              )}
              {!isQuoteSlider && (
                <div className={classes.numberContainer}>
                  <div className={classes.number}>{slide.number}</div>
                </div>
              )}
              <Typography
                align={isQuoteSlider ? "left" : "center"}
                className={classes.slideTitle}
                component="h3"
              >
                {slide.title}
              </Typography>
              <Typography
                align={isQuoteSlider ? "left" : "center"}
                className={classes.slideText}
              >
                {slide.text}
              </Typography>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

const useStyle = makeStyles(theme => ({
  outsideContainer: {
    paddingBottom: 50,
    backgroundColor: ({ blocBackgroundColor }) => blocBackgroundColor,
  },
  slidesContainer: {
    position: "absolute",
    display: "flex",
    alignItems: "flex-start",
    top: 120,
    left: ({ isQuoteSlider }) =>
      isQuoteSlider ? "calc(50% - 80px)" : "calc(50% - 600px + 380px)",
    ["@media (max-width:800px)"]: {
      left: ({}) => "40%",
    },
    ["@media (max-width:580px)"]: {
      position: "unset",
      width: "100%",
      overflowX: "auto",
      "&::-webkit-scrollbar": {
        display: "none",
      },
      overflowY: "hidden",
    },
  },
  text: { marginTop: theme.spacing(2) },
  numberBlocContainer: {
    position: "absolute",
    top: -80,
    left: "10%",
    right: "10%",
    display: "flex",
    justifyContent: "center",
  },
  numberBloc: {
    width: 60,
    height: 60,
    color: ({ color }) => color,
    borderRadius: 50,
    border: `1px solid ${theme.palette.secondary.main}`,
    backgroundColor: ({ numberBackgroundColorComputed }) =>
      numberBackgroundColorComputed,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontWeight: 700,
    fontSize: 30,
  },
  textBloc: {
    maxWidth: 430,
    backgroundColor: "white",
    zIndex: 2,
    padding: 50,
    position: "sticky",
    top: 280,
    left: ({ isQuoteSlider }) =>
      isQuoteSlider ? "calc(50% - 450px)" : "calc(50% - 600px)",
    ["@media (max-width:800px)"]: {
      left: ({}) => 0,
    },
    ["@media (max-width:580px)"]: {
      marginRight: theme.spacing(2),
      marginLeft: theme.spacing(2),
      padding: ({ displayNumber }) => (displayNumber ? 50 : 20),
      top: 50,
      paddingTop: 20,
      width: `calc(100% - ${theme.spacing(4)}px)`,
      maxWidth: "unset",
      marginBottom: -20,
      order: 0,
    },
  },
  textBlocInner: {
    position: "relative",
  },
  container: {
    overflowX: "auto",
    overflowY: "hidden",
    height: ({ isQuoteSlider }) => (isQuoteSlider ? 770 : 1000),
    position: "relative",
    paddingBottom: 70,
    "&::-webkit-scrollbar": {
      height: "8px",
    },
    "&::-webkit-scrollbar-track": {
      borderRadius: "10px",
      marginLeft: 500,
      marginRight: 500,
    },
    "&::-webkit-scrollbar-thumb": {
      borderRadius: "10px",
      backgroundColor: "transparent",
    },
    [theme.breakpoints.down("xs")]: {
      "&::-webkit-scrollbar": {
        display: "none",
      },
    },
    [theme.breakpoints.down("md")]: {
      "&::-webkit-scrollbar-track": {
        marginLeft: 200,
        marginRight: 200,
      },
    },
    "&:hover": {
      "&::-webkit-scrollbar-track": {
        backgroundColor: "#f6f6f6",
        border: "1px solid #f6f6f6",
      },
      "&::-webkit-scrollbar-thumb": {
        borderRadius: "10px",
        backgroundColor: theme.palette.secondary.main,
      },
    },
    ["@media (max-width:580px)"]: {
      overflowX: "hidden",
      height: ({ isQuoteSlider }) => "auto",
      paddingBottom: 30,
    },
  },
  slide: {
    display: "flex",
    flexDirection: "column",
    width: 460,
    marginRight: theme.spacing(3),
    minWidth: 0,
    position: "relative",
    paddingTop: ({ isQuoteSlider }) => (isQuoteSlider ? 190 : 0),
    paddingBottom: ({ isQuoteSlider }) => (isQuoteSlider ? 190 : 0),
    paddingLeft: ({ isQuoteSlider }) => (isQuoteSlider ? 90 : 0),
    paddingRight: ({ isQuoteSlider }) => (isQuoteSlider ? 24 : 0),
    backgroundColor: ({ isQuoteSlider }) =>
      isQuoteSlider ? "#073331" : "transparent",
    color: ({ isQuoteSlider }) =>
      isQuoteSlider ? theme.palette.yellow : theme.palette.secondary.main,
    ["@media (max-width:580px)"]: {
      width: ({ isQuoteSlider }) => (isQuoteSlider ? "80vw" : "90vw"),
      minWidth: ({ isQuoteSlider }) => (isQuoteSlider ? "80vw" : "90vw"),
      backgroundColor: ({ isQuoteSlider }) => "transparent",
      color: ({ isQuoteSlider }) => theme.palette.secondary.main,
      paddingTop: ({ isQuoteSlider }) => (isQuoteSlider ? 150 : 0),
      paddingBottom: ({ isQuoteSlider }) => (isQuoteSlider ? 30 : 0),
      paddingLeft: ({ isQuoteSlider }) => (isQuoteSlider ? 40 : 0),
      paddingRight: ({ isQuoteSlider }) =>
        isQuoteSlider ? theme.spacing(3) : 0,
    },
  },
  slideImage: {
    width: "100%",
    height: 600,
    ["@media (max-width:580px)"]: {
      width: "100%",
      height: "130vw",
    },
  },
  quote: {
    fontSize: 279,
    fontWeight: 700,
    position: "absolute",
    top: 0,
    left: theme.spacing(3),
    color: "inherit",
    ["@media (max-width:580px)"]: {
      fontSize: 200,
      top: 50,
      left: -30,
    },
  },
  numberContainer: {
    backgroundColor: ({ blocBackgroundColor }) => blocBackgroundColor,
    borderRadius: 150,
    width: 140,
    height: 140,
    marginTop: -70,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    zIndex: 1,
    alignSelf: "center",
  },
  number: {
    backgroundColor: "white",
    width: 100,
    height: 100,
    borderRadius: 50,
    color: theme.palette.secondary.main,
    fontWeight: 500,
    fontSize: 65,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  slideTitle: {
    fontWeight: 700,
    fontSize: 44,
    letterSpacing: "-2px",
    ["@media (max-width:580px)"]: {
      fontSize: ({ isQuoteSlider }) => (isQuoteSlider ? 42 : 30),
      lineHeight: ({ isQuoteSlider }) => (isQuoteSlider ? "49px" : "33px"),
      letterSpacing: ({ isQuoteSlider }) => (isQuoteSlider ? "-4%" : "-2px"),
    },
  },
  slideText: {
    marginRight: ({ isQuoteSlider }) => (isQuoteSlider ? 0 : theme.spacing(5)),
    marginLeft: ({ isQuoteSlider }) => (isQuoteSlider ? 0 : theme.spacing(5)),
    marginTop: ({ isQuoteSlider }) =>
      isQuoteSlider ? theme.spacing() : theme.spacing(3),
  },
}))
