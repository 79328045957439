import * as React from "react"
import {
  makeStyles,
  Box,
  Container,
  Grid,
  Typography,
  useTheme,
} from "@material-ui/core"
import { Arrow } from "./Arrow"
import Background from "./assets/grid.svg"

const useThemeFromColor = color => {
  const theme = useTheme()
  const chartColor = {
    Green: {
      primaryBackgroundColor: theme.palette.secondary.main,
      secondaryBackgroundColor: theme.palette.common.white,
      text: theme.palette.primary.main,
    },
    Pink: {
      primaryBackgroundColor: theme.palette.primary.main,
      secondaryBackgroundColor: theme.palette.common.white,
      text: theme.palette.secondary.main,
    },
    Yellow: {
      primaryBackgroundColor: theme.palette.yellow,
      secondaryBackgroundColor: theme.palette.common.white,
      text: theme.palette.secondary.main,
    },
    White: {
      primaryBackgroundColor: theme.palette.common.white,
      secondaryBackgroundColor: theme.palette.primary.main,
      text: theme.palette.secondary.main,
    },
  }

  return chartColor[color || "Green"]
}

export default function TopBlock({
  backgroundColor: bgc,
  backgroundImage,
  image,
  title,
  subtitle,
}) {
  const {
    primaryBackgroundColor,
    secondaryBackgroundColor,
    text,
  } = useThemeFromColor(bgc)
  const isCentered = !Boolean(image)
  const classes = useStyles({
    text,
    image,
    primaryBackgroundColor,
    secondaryBackgroundColor,
    backgroundImage,
    isCentered,
  })
  return (
    <div className={classes.container}>
      <Container maxWidth="md">
        {isCentered && (
          <Box pt={{ xs: 10, sm: 20 }}>
            <Grid
              container
              justify="center"
              alignItems="center"
              className={classes.content}
            >
              <Grid item>
                <Box pl={{ sm: 15, md: 35 }} pr={{ sm: 15, md: 35 }}>
                  <Typography variant="h1" className={classes.title}>
                    {title}
                  </Typography>
                  <Box mt={2}>
                    <Typography variant="body1">{subtitle}</Typography>
                  </Box>
                </Box>
              </Grid>
            </Grid>
            <div className={classes.arrow}>
              <Arrow
                primaryColor={secondaryBackgroundColor}
                secondaryColor={primaryBackgroundColor}
              />
            </div>
          </Box>
        )}
        {!isCentered && (
          <Box pt={18}>
            <Grid container justify="center" className={classes.content}>
              <Grid item sm={12} md={6}>
                <Box pr={{ sm: 10, md: 20 }}>
                  <Typography variant="h1" className={classes.title}>
                    {title}
                  </Typography>
                  <Box mt={2}>
                    <Typography variant="body1">{subtitle}</Typography>
                  </Box>
                </Box>
              </Grid>
              <Grid
                item
                sm={12}
                md={6}
                container
                className={classes.imgContainer}
              >
                <div className={classes.imageContainer}>
                  <Background className={classes.backgroundImage} />
                  <div alt="header image" className={classes.image} />
                </div>
                <div className={classes.arrowAbsolute}>
                  <Arrow
                    primaryColor={secondaryBackgroundColor}
                    secondaryColor={primaryBackgroundColor}
                  />
                </div>
              </Grid>
            </Grid>
          </Box>
        )}
      </Container>
    </div>
  )
}

const useStyles = makeStyles(theme => ({
  container: ({
    text,
    primaryBackgroundColor,
    secondaryBackgroundColor,
    isCentered,
  }) => ({
    position: "relative",
    [theme.breakpoints.up("sm")]: {
      background: `linear-gradient(180deg, ${primaryBackgroundColor} ${
        isCentered ? "602px" : "90%"
      }, ${secondaryBackgroundColor} 0)`,
    },
    background: `linear-gradient(180deg, ${primaryBackgroundColor} ${
      isCentered ? "521px" : "750px"
    }, ${secondaryBackgroundColor} 0)`,
    color: text,
  }),
  content: ({ backgroundImage }) => ({
    minHeight: 442,
    background: `no-repeat center url('${backgroundImage}')`,
  }),
  title: ({ text }) => ({
    color: text,
    fontSize: 48,
    letterSpacing: "-3px",
    [theme.breakpoints.up("sm")]: {
      fontSize: 85,
    },
  }),
  imgContainer: {
    "& > img": {
      width: "100%",
      height: "auto",
    },
    [theme.breakpoints.up("md")]: {
      marginTop: 0,
      justifyContent: "flex-end",
      position: "initial",
    },
    position: "relative",
    marginTop: theme.spacing(7),
    justifyContent: "center",
  },
  arrow: {
    zIndex: 2,
    marginTop: -42,
    display: "flex",
    justifyContent: "center",
  },
  arrowAbsolute: {
    position: "absolute",
    zIndex: 2,
    top: -25,
    display: "flex",
    justifyItems: "center",
    [theme.breakpoints.up("md")]: {
      top: "calc(90% - 42px)",
      left: "calc(50% - 400px)",
    },
  },
  imageContainer: {
    position: "relative",
    width: "100%",
    height: 700,
    justifyContent: "center",
    display: "flex",

    alignItems: "flex-end",

    [theme.breakpoints.down("xs")]: {
      height: "100vw",
    },
  },
  backgroundImage: {
    position: "absolute",
    right: 0,
    top: 0,
    width: "100%",
    height: "auto",

    zIndex: 0,
  },
  image: {
    zIndex: 1,
    background: ({ image }) => `no-repeat center url('${image}')`,
    [theme.breakpoints.up("sm")]: {
      padding: "30px 34px",
    },
    padding: "25px 28px",
    width: "90%",
    height: "96%",
    backgroundSize: ({}) => "cover",
    position: "relative",
  },
}))
