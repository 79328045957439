import React from "react"
import { makeStyles, Typography, useTheme } from "@material-ui/core"

import { RoughNotation } from "react-rough-notation"

export default function Hightlight({ firstText, secondText, highlightText }) {
  const classes = useStyle()
  const theme = useTheme()
  return (
    <div className={classes.container}>
      <Typography className={classes.text}>{firstText}</Typography>
      {secondText && secondText !== "" && (
        <Typography className={classes.text}>{secondText}</Typography>
      )}
      <div className={classes.highlightTextContainer}>
        <RoughNotation
          iterations={4}
          type="highlight"
          color={theme.palette.yellow}
          show
        >
          <Typography className={classes.highlightText}>
            {" "}
            {highlightText}
          </Typography>
        </RoughNotation>
      </div>
    </div>
  )
}

const useStyle = makeStyles(theme => ({
  highlightTextContainer: {
    margin: "auto",
    maxWidth: 500,
    marginTop: 72,
    position: "relative",
  },
  container: {
    maxWidth: 750,
    margin: "auto",
    paddingTop: 100,
    paddingBottom: 130,
    [theme.breakpoints.down("sm")]: {
      paddingTop: 60,
      paddingBottom: 60,
      paddingRight: theme.spacing(2),
      paddingLeft: theme.spacing(2),
    },
  },
  text: {
    color: theme.palette.secondary.main,
    textAlign: "center",
    fontSize: 39,
    [theme.breakpoints.down("sm")]: {
      fontSize: 22,
    },
  },
  highlightText: {
    margin: "auto",
    color: theme.palette.secondary.main,
    textAlign: "center",
    fontSize: 26,
    fontWeight: 500,
    [theme.breakpoints.down("sm")]: {
      fontSize: 22,
      marginTop: theme.spacing(5),
    },
  },
}))
