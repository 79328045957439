import React, { useState } from "react"
import {
  makeStyles,
  Typography,
  useTheme,
  Container,
  useMediaQuery,
} from "@material-ui/core"

export default function RightPrice({ image, title, text }) {
  const [isActive, setIsActive] = useState(null)
  const classes = useStyle()
  const theme = useTheme()
  const isTablet = useMediaQuery(theme.breakpoints.down("md"))

  return (
    <Container maxWidth="md" className={classes.cont}>
      <div onMouseLeave={() => setIsActive(null)}>
        <div className={classes.titles}>
          <Typography
            variant="h1"
            component="h2"
            color="secondary"
            className={classes.title}
          >
            {title}
          </Typography>
          <Typography
            variant="body1"
            color="secondary"
            className={classes.description}
          >
            {text}
          </Typography>
        </div>

        <div className={classes.rightPrice__container}>
          <div className={classes.left__part}>
            <div className={classes.buttons__container}>
              {content.buttons.map(({ id, label, percent, color }) => {
                return (
                  <div
                    className={classes.button__container}
                    onMouseEnter={() => setIsActive(id)}
                    key={id}
                  >
                    <button
                      style={{
                        backgroundColor: color,
                        minWidth:
                          isActive === id
                            ? isTablet
                              ? "250px"
                              : "350px"
                            : isTablet
                            ? "20px"
                            : "80px",
                      }}
                      className={classes.button}
                      key={id}
                    ></button>
                    <p
                      className={classes.button__label}
                      style={{
                        color:
                          isActive === id
                            ? "white"
                            : theme.palette.secondary.main,
                        fontWeight: isActive === id ? "bold" : "normal",
                      }}
                    >
                      {label}
                    </p>
                  </div>
                )
              })}
            </div>
          </div>
          <div className={classes.right__part}>
            <div className={classes.chart__container}>
              <img
                className={classes.chart__image}
                src={image}
                alt="Les justes prix"
              />

              {content.buttons.map(({ id, percent, color }) => {
                return (
                  <div
                    key={id}
                    style={{
                      backgroundColor: color,
                      height: `${percent}%`,
                      opacity:
                        isActive === null || isActive === id ? "1" : "0.2",
                    }}
                    className={classes.chart__parts}
                    onMouseEnter={() => setIsActive(id)}
                    onClick={() => setIsActive(id)}
                  >
                    <p
                      style={{
                        fontWeight: isActive === id ? "bold" : "normal",
                      }}
                      className={classes.chart__label}
                    >
                      {percent}%
                    </p>
                  </div>
                )
              })}
            </div>
          </div>
        </div>
      </div>
    </Container>
  )
}

const useStyle = makeStyles(theme => ({
  cont: {
    [theme.breakpoints.down("md")]: {
      paddingRight: 0,
    },
  },
  rightPrice__container: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    overflow: "hidden",
    width: "100%",
    position: "relative",

    [theme.breakpoints.down("sm")]: {
      paddingTop: theme.spacing(4),
      alignItems: "flex-start",
    },
  },

  right__part: {
    height: "100%",
    [theme.breakpoints.down("sm")]: {
      position: "absolute",
      right: "-410px",
    },
  },
  titles: {
    maxWidth: "450px",
    paddingRight: theme.spacing(2),
  },
  title: {
    marginBottom: theme.spacing(4),
  },
  description: {
    lineHeight: "140%",
  },

  buttons__container: {
    marginTop: theme.spacing(4),
    display: "flex",
    flexDirection: "column",
  },
  button__container: {
    display: "flex",
    alignItems: "center",
    position: "relative",
    width: "100%",
    "& :hover": {
      cursor: "pointer",
    },
  },
  button: {
    margin: `${theme.spacing(1)}px 0`,
    border: 0,
    borderRadius: "100px",
    padding: "24px 0",
    width: "auto",
    transition: "all 400ms",
    color: theme.palette.secondary.main,
    fontFamily: "GT Welsheim Pro, Arial, sans-serif",

    [theme.breakpoints.down("sm")]: {
      padding: "20px 0",
    },
  },
  button__label: {
    position: "absolute",
    left: theme.spacing(12),
    top: "5px",
    fontSize: "19px",
    fontFamily: "GT Welsheim Pro, Arial, sans-serif",
    whiteSpace: "nowrap",

    [theme.breakpoints.down("sm")]: {
      fontSize: "17px",
      left: theme.spacing(4),
      top: "1px",
    },
  },
  chart__container: {
    height: "500px",
    width: "440px",
    position: "relative",
    textAlign: "right",

    "& :hover": {
      cursor: "pointer",
    },

    [theme.breakpoints.down("sm")]: {
      height: "90%",
    },
  },
  chart__label: {
    position: "absolute",
    right: "450px",
    textAlign: "right",
    color: theme.palette.secondary.main,
  },
  chart__parts: {
    transition: "all 400ms",
    opacity: 1,
    display: "flex",
    alignItems: "center",
    "& :hover": {
      cursor: "pointer",
    },
  },
  chart__image: {
    width: "100%",
    height: "auto",
    position: "absolute",
    left: 0,
    top: 30,
    zIndex: 2,
    pointerEvents: "none",
  },
}))

const content = {
  buttons: [
    { id: "collecte", label: "Collecte", percent: 20, color: "#FFD747" },
    {
      id: "reparation",
      label: "Réparation & nettoyage",
      percent: 25,
      color: "#A4E7DB",
    },
    { id: "pieces", label: "Pièces détachées", percent: 10, color: "#ACCEFF" },
    { id: "livraison", label: "Livraison", percent: 15, color: "#F7BDBD" },
    { id: "manutention", label: "Manutention", percent: 10, color: "#073331" },
    {
      id: "marketing",
      label: "Marketing & publicité",
      percent: 10,
      color: "#005646",
    },
    { id: "marge", label: "Marge", percent: 10, color: "#FF757B" },
  ],
}
